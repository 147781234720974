<template>
	<div class="content-wrap humiture">
		<Spin fix v-if="loading"></Spin>

		<!--搜索框-->
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="药品编号">
				<Input placeholder="请输入药品编号" v-model="searchParams.id" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="状态">
				<Select
					v-model="searchParams.status"
					:transfer="true"
					placeholder="请选择状态"
				>
					<Option
						v-for="item in ['已发放', '已回收']"
						:value="item"
						:key="item"
					>
						{{ item }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>

		<!--操作按钮-->
		<CtmsAction>
			<Button @click="() => this.$router.go(-1)">返回</Button>
		</CtmsAction>

		<CtmsAction>
			<Row>
				<Col span="6">仓库编号：200202</Col>
				<Col span="6">仓库名称：200202</Col>
				<Col span="6">温度警戒区间：200202</Col>
				<Col span="6">湿度警戒区间：200202</Col>
			</Row>
		</CtmsAction>

		<!--主内容-->
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
	</div>
</template>

<script>
import api from "@/api/project/project"
import { mapState } from "vuex"

const { apiGetPage } = api

export default {
	name: "pharmacyAdd",
	data() {
		return {
			listData: [],
			columns: [
				{ title: "药品编号", key: "name", minWidth: 100 },
				{ title: "药品数量", key: "protNo", minWidth: 150 },
				{ title: "批号", key: "researcher", minWidth: 120 },
				{ title: "有效期", key: "protNo", minWidth: 120 },
				{ title: "存储条件", key: "protNo", minWidth: 120 },
				{ title: "创建时间", key: "protNo", minWidth: 120 },
				{ title: "状态", key: "protNo", minWidth: 120 }
			],
			loading: false,
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				id: "",
				status: ""
			}
		}
	},
	created() {
		this.initList()
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = { id: "", status: "" }
			this.searchHandle()
		},
		searchHandle() {
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>
